import React, {ReactNode} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {getBlLocaleFromString} from "lib/BlLocale";
import {BundleEntry} from "components/bundle-entry/BundleEntry";
import {isNotNil} from "lib/functions";
import {FormattedMessage} from "react-intl";
import {JustDrinkBanner} from "./components/justdrink-banner/JustDrinkBanner";

const otherBanners = document.getElementsByClassName("carl-hiding-banner");
if (otherBanners.length === 0) {
    const target = document.getElementById('carl-banner');
    const rawLocaleCode = target?.dataset?.['localeCode'];
    const localeCode = getBlLocaleFromString(rawLocaleCode);
    //const rawImage = target?.dataset?.['carlImage'];
    //const image = getCarlBannerImage(rawImage);
    const title = target?.dataset?.['title'];
    const titleMessageId = target?.dataset?.['titleMsgId'];
    const subtitle = target?.dataset?.['subtitle'];
    const subtitleMessageId = target?.dataset?.['subtitleMsgId'];

    const titleContent: ReactNode = titleMessageId != null ? <FormattedMessage id={titleMessageId} /> : title;
    const subtitleContent: ReactNode = subtitleMessageId != null ? <FormattedMessage id={subtitleMessageId} /> : subtitle;

    if (titleContent != null || subtitleContent != null) {
        const prefetch = [titleMessageId, subtitleMessageId].filter(isNotNil);

        ReactDOM.render(
            <BundleEntry
                bundleName="carl-banner"
                locale={localeCode}
                prefetchTranslations={prefetch}
            >
                {/* Note: due to time constraints, the component has not been fully refactored. At this stage, only one banner image ("jd-bottles") exist.
                 Therefore, the backend code has not been changed. However, in case of multiple banner images in the future, the following component can
                 be easily adjusted to fit the purpose. In this case, however, the backend code would need a change as well.*/}
                <JustDrinkBanner
                    image={"jd-bottles"}
                    title={titleContent}
                    subtitle={subtitleContent}
                />
                {/*<CarlBanner
                    image={image}
                    title={titleContent}
                    subTitle={subtitleContent}
                />*/}
            </BundleEntry>,
            target
        );
    }
}
